<template>
    <div>
        <v-row justify="center">

            <v-dialog v-model="dialog" persistent max-width="1200px">
                <v-card>
                    <v-card-title>
                        <span class="lfont">ຕັ້ງວັນລາພັກ ປີ <span class="text-primary">{{ year
                        }}</span></span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="5" lg="5">
                                    <v-btn color="primary" style="color: white;" @click="dialogAddEmp = !dialogAddEmp">
                                        Choose Employee (<span class="text-danger">{{ selectedEmployees.length
                                        }}</span>)
                                    </v-btn>
                                    <v-data-table :headers="headers" :items="addIndexToEmployees(selectedEmployees)"
                                        :height="maxHeight" class="elevation-1 hide-headers lfont">
                                        <template v-slot:item.action="{ item }">
                                            <v-btn @click="deleteEmployee(item.id)" icon>
                                                <v-icon color="red">mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-data-table>
                                </v-col>
                                <v-col cols="12" md="7" lg="7">

                                    <table class="lfont holiday-table">
                                        <tr>
                                            <td>ວັນພັກ</td>
                                            <td>% ຍົກຍອດຈາກປີຜ່ານມາ</td>
                                        </tr>
                                        <tr v-for="(item, index) in company_holiday">
                                            <td>
                                                <v-checkbox :label="`${item.name} ${item.amount} ${$t('ScanPoint.day')}`"
                                                    :value="item.id" style="margin-top: 0px;"
                                                    v-model="company_holiday_selected"></v-checkbox>
                                            </td>
                                            <td>
                                                <v-slider v-model="item.percent" color="red" :label="`${item.percent} %`"
                                                    hint="Be honest" min="0" max="100" thumb-label></v-slider>
                                            </td>
                                        </tr>
                                    </table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" text @click="resetEmployeeLeave">
                            SAVE
                        </v-btn>
                        <v-btn color="warning" text @click="clearEmployeeSelected">
                            Clear
                        </v-btn>
                        <v-btn color="red" text @click="$emit('save', false)">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogAddEmp" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span>Employee List</span>
                        <v-spacer></v-spacer>
                        <v-row>
                            <v-col cols="12" md="5" lg="5">
                                <label class="label-input">{{ $t("ScanPoint.branch") }}</label>
                                <v-autocomplete filled dense item-text="branch_name" item-value="id" outlined
                                    :items="listBranch" hide-selected v-model="branch_id" @change="filterWithBranch">
                                    <template v-slot:item="data">
                                        <template>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.branch_name"></v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="5" lg="5">
                                <label class="label-input">{{ $t("ScanPoint.dept") }}</label>
                                <v-autocomplete filled dense item-text="name" item-value="id" outlined
                                    :items="listDepartment" hide-selected v-model="department_id"
                                    @change="filterWithDepartment">
                                    <template v-slot:item="data">
                                        <template>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="2" lg="2">
                                <label for="" style="color: white;">.</label><br>
                                <v-btn color="primary" style="color: white;" @click="all_employee">
                                    ALL
                                </v-btn>
                            </v-col>
                            <v-col cols="12" md="12" lg="12">
                                <label class="label-input"><span class="text-alert">*</span>ເລືອກເພດ</label>
                                <v-radio-group row v-model="gender" @change="filterWithGender()">
                                    <v-radio class="label-input" value="all" :label="$t('ScanPoint.select_gender_all')">
                                    </v-radio>
                                    <v-radio class="label-input" value="Male" :label="$t('Employee.form.male')">
                                    </v-radio>
                                    <v-radio class="label-input" value="Female" :label="$t('Employee.form.female')">
                                    </v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" md="12" lg="12">
                                <v-form class="form-autocomplete">
                                    <v-autocomplete filled chips color="blue-grey lighten-4" label="Select Employee"
                                        item-text="name" item-value="id" multiple outlined :items="allEmployee"
                                        hide-selected v-model="data.employee">
                                        <template v-slot:selection="data">
                                            <v-chip v-bind="data.attrs" :input-value="data.selected" close
                                                @click="data.select" @click:close="removeItemFromTable(data.item)">
                                                <v-avatar left>
                                                    <!-- <v-img :src="data.item.profile"></v-img> -->
                                                </v-avatar>
                                                {{ data.item.name }} {{ data.item.surname }}
                                            </v-chip>
                                        </template>
                                        <template v-slot:item="data">
                                            <template v-if="typeof data.item !== 'object'">
                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                            </template>
                                            <template v-else>
                                                <v-list-item-avatar>

                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                    <v-list-item-subtitle v-html="data.item.surname"
                                                        class="lfont"></v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-actions>
                        <v-btn color="primary" text @click="selecting_employee">
                            ADD
                        </v-btn>
                        <v-btn color="red" text @click="dialogAddEmp = false">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        dialog: "",
        year: "",
    },
    data() {
        return {
            headers: [
                { text: 'No', value: 'index' },
                { text: 'Name', value: 'name' },
                { text: 'Action', value: 'action', sortable: false },
            ],
            maxHeight: 300,

            dialogAddEmp: false,
            company_holiday: [],
            company_holiday_selected: [],
            allEmployee: [],
            allListEmployee: [],
            listDepartment: [],
            listBranch: [],
            department_id: "",
            branch_id: "",
            filter: "",
            gender: "all",
            data: {
                employee: [],
            },
            selectedEmployees: [],
            server_errors: {
                year: "",
                employee_id: [],
                company_holiday: []
            },
            get_all_employee: false
        }
    },
    methods: {
        filterWithDepartment() {
            this.allEmployee = this.allListEmployee.filter(item => item.company_department_id === this.department_id);
        },
        filterWithBranch() {
            this.allEmployee = this.allListEmployee.filter(item => item.company_branch_id === this.branch_id);
        },
        filterWithGender() {
            this.allEmployee = this.allListEmployee.filter(item => item.gender === this.gender);
        },
        addIndexToEmployees(employees) {
            return employees.map((employee, index) => ({
                ...employee,
                index: index + 1,
                name: `${employee.name} ${employee.surname}`,
                action: true,
            }));
        },
        deleteEmployee(id) {
            this.selectedEmployees = this.selectedEmployees.filter(item => item.id != id);
        },
        clearEmployeeSelected() {
            this.selectedEmployees = [];
        },
        selecting_employee() {
            this.selectedEmployees = []
            this.dialogAddEmp = !this.dialogAddEmp;
            let employeeSelect = this.data.employee.map((id) => {
                const find = this.allEmployee.filter((item) => {
                    return id === item.id;
                });
                if (find.length) {
                    return find[0];
                }
                return {};
            });
            let addNew = employeeSelect.filter(item2 => !this.selectedEmployees.some(item1 => item1.id === item2.id));
            if (addNew.length == 0) {
                if (this.gender == 'all') {
                    this.allEmployee = this.allListEmployee;
                } else if (this.gender != 'all') {
                    this.allEmployee = this.allListEmployee.filter(item => item.gender === this.gender);
                }
                if (this.department_id != '') {
                    this.allEmployee = this.allListEmployee.filter(item => item.company_department_id === this.department_id);
                }
                if (this.branch_id != '') {
                    this.allEmployee = this.allListEmployee.filter(item => item.company_branch_id === this.branch_id);
                }
                addNew = this.allEmployee;
            }
            this.selectedEmployees = [...this.selectedEmployees, ...addNew];
            this.data.employee = [];
            this.checkEmployeeNoAdd();

        },
        checkEmployeeNoAdd() {
            this.allEmployee = this.allEmployee.filter(item2 => !this.selectedEmployees.some(item1 => item1.id === item2.id));
        },
        resetEmployeeLeave() {
            let employee_id = this.selectedEmployees.map(item => item.id);
            let company_holiday_selected = this.company_holiday.filter(item => this.company_holiday_selected.includes(item.id));
            company_holiday_selected = company_holiday_selected.map(item => ({
                id: item.id,
                bring_up: item.percent
            }));
            this.$emit('save', false);
            this.$emit('load', true);
            let items = {
                year: this.year,
                employee_id: employee_id,
                company_holiday: company_holiday_selected,
            };
            this.$axios
                .post(`company/reset/employee/holiday`, items)
                .then((res) => {
                    if (res.status === 200) {
                        this.$emit('success');
                    }
                })
                .catch((error) => {
                    this.$emit('load', false);
                    if (error.response.status === 422) {
                        let obj = error.response.data.errors;
                        if (!obj) {
                            obj = error.response.data;
                            let res = "";
                            for (let [key, value] of Object.entries(obj)) {
                                res = value;
                            }
                            this.$notification.ShowErrors("top-right", "danger", 3000, "ເລືອກວັນພັກທີ່ຕ້ອງການດາວໂຫຼດກ່ອນ !!");
                        } else {
                            for (let [key, value] of Object.entries(obj)) {
                                this.server_errors[key] = value[0];
                                this.$notification.ShowErrors(
                                    "top-right",
                                    "danger",
                                    3000,
                                    (this.server_errors[key] = value[0])
                                );
                            }
                        }
                    }
                });
        },
        all_employee() {
            this.selectedEmployees = this.allListEmployee;
            this.department_id = ''
            this.branch_id = ''
            this.dialogAddEmp = false;
        },
        fetchEmployeeWithFilter() {
            this.get_all_employee = false;
            this.fetchAllEmployee();
        },
        fetchAllEmployee() {
            this.data.employee = [];
            this.$emit('load', true);
            this.$axios
                .get(`company/all/employee-name`, {
                    params: {
                        filter: this.filter,
                        department_id: this.department_id,
                        branch_id: this.branch_id,
                    },
                })
                .then((res) => {
                    this.$emit('load', false);
                    this.allEmployee = res.data.data;
                    this.allListEmployee = res.data.data;
                    if (res.data.data.length == 0) {
                        setTimeout(() => {
                            this.$notification.OpenNotification(
                                "ແຈ້ງເຕືອນ.",
                                "ບໍມີຂໍ້ມູນພະນັກງານ.",
                                "top-right",
                                "danger",
                                3000
                            );
                        }, 300);
                    }
                })
                .catch(() => {
                    this.$emit('load', false);
                });
        },
        fetchDepartment() {
            this.$axios.get(`company/list/departments/selected`, {}).then((res) => {
                if (res.status === 200) {
                    this.listDepartment = res.data.data;
                }
            });
        },
        fetchBranch() {
            this.$axios
                .get(`company/list/company/branches`)
                .then((res) => {
                    if (res) {
                        this.listBranch = res.data.companyBranches;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchCompanyHoliday() {
            this.$axios
                .get(`company/list/holidays`)
                .then((res) => {
                    if (res) {
                        let company_holiday = res.data.data;
                        this.company_holiday = company_holiday.map((holiday, index) => ({
                            ...holiday,
                            id: holiday.id,
                            name: holiday.name,
                            percent: 0
                        }));
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        removeItemFromTable(item) {
            const index = this.data.employee.indexOf(item.id);
            if (index >= 0) {
                this.data.employee.splice(index, 1);
            }
        },
    },
    created() {
        this.fetchDepartment();
        this.fetchBranch();
        this.fetchAllEmployee();
        this.fetchCompanyHoliday();
    },
}
</script>
 
<style scoped lang="scss">
.hide-headers .v-data-table__header {
    display: none;
}

.holiday-table {
    width: 100%;

    tr {
        td {
            padding-right: 10px;
            padding-left: 10px;
            border: 1px solid rgb(232, 232, 232);
            border-radius: 10px;
        }
    }
}
</style>