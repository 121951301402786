<template>
  <div class="main-container">
    <div class="section-content">
      <v-row dense>
        <v-col cols="12" md="4" lg="4" class="search">
          <label class="label-input">{{ $t("Employee.search_filter") }}</label>
          <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense
            prepend-inner-icon="mdi-magnify" placeholder="..." v-model="searchItem" @keypress.enter="fetchEmployee"
            hide-details>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <label class="label-input">{{ $t("Employee.dept") }}</label>
          <v-autocomplete filled dense item-text="name" item-value="id" outlined :items="listDepartment" hide-selected
            v-model="department_id" @change="fetchEmployee">
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <label class="label-input">{{ $t("Employee.branch") }}</label>
          <v-autocomplete filled dense item-text="branch_name" item-value="id" outlined :items="listBranch"
            hide-selected v-model="branch_id" @change="fetchEmployee">
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.branch_name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="2" lg="2">
          <label class="label-input">{{ $t("year") }}</label>
          <v-select :items="years" outlined dense v-model="year" @change="fetchEmployee"></v-select>
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <label class="label-input">Import Excel</label><br />
          <v-btn class="btn-import mr-4" @click="importExcel()">
            <span><i class="fal fa-file-export" style="margin-right: 4px"></i></span>Import Excel
          </v-btn>
        </v-col>
        <v-col cols="12" md="2" lg="2">
          <label class="label-input">Reset</label><br />
          <v-btn class="btn-reset mr-4" @click="dialogResetHoliday = true">
            <span><i class="fal fa-repeat" style="margin-right: 4px"></i></span>Reset All
          </v-btn>
        </v-col>
        <v-col cols="12" md="2" lg="2">
          <label class="label-input">Download Excel</label><br />
          <v-btn color="green" @click="exportExcel" class="text-white">
            <i class="fal fa-file-excel" style="margin-right: 4px; color: white"></i>
            Export
          </v-btn>
        </v-col>
      </v-row>
      <table class="table">
        <tr>
          <th width="50">#</th>
          <th width="130">{{ $t("EmpHoliday.table.emp_number") }}</th>
          <th>{{ $t("EmpHoliday.table.emp_name") }}</th>
          <th></th>
          <th>{{ $t("EmpHoliday.table.leave") }}</th>
        </tr>
        <tr v-for="(item, index) in listEmployee" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ item.emp_number }}</td>
          <td>{{ item.name }} {{ item.surname }}</td>
          <td>
            <span v-if="item.gender == 'Male'">{{
            $t("Employee.form.male")
          }}</span>
            <span v-else>{{ $t("Employee.form.female") }}</span>
          </td>
          <td>
            <v-list subheader two-line style="padding: 0px !important">
              <v-list-item v-for="(leave_item, k) in item.holiday" :key="k" style="padding: 0px !important">
                <v-list-item-avatar style="width: 30px !important; height: 30px !important">
                  <v-icon class="blue lighten-1" dark style="font-size: 20px !important">
                    mdi-calendar-today
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="leave_item.name"></v-list-item-title>

                  <v-list-item-subtitle>ຈຳນວນມື້ພັກ:
                    <span class="text-primary">{{ leave_item.amount.set_days }}ມື້</span></v-list-item-subtitle>

                  <v-list-item-subtitle v-if="leave_item.bring_up &&
            (leave_item.bring_up.day > 0 ||
              leave_item.bring_up.hour > 0 ||
              leave_item.bring_up.minute > 0)
            ">
                    <span class="text-warning">ຍົກຍອດມາຈາກປີທີ່ແລ້ວ:</span>
                    <span class="text-danger">{{ leave_item.bring_up.day }}ມື້
                      {{ leave_item.bring_up.hour }}ຊົ່ວໂມງ
                      {{ leave_item.bring_up.minute }}ນາທີ</span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>ຈຳນວນມື້ພັກທີ່ໄດ້ຮັບ:
                    <span class="text-primary">{{ leave_item.amount.days }}ມື້
                      {{ leave_item.amount.hours }}ຊົ່ວໂມງ
                      {{ leave_item.amount.minutes }}ນາທີ</span></v-list-item-subtitle>
                  <v-list-item-subtitle>
                    ໃຊ້ແລ້ວ:
                    <span class="text-danger">{{ leave_item.using.days }}ມື້
                      {{ leave_item.using.hours }}ຊົ່ວໂມງ
                      {{ leave_item.using.minute }}ນາທີ</span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    ຍັງເຫຼືອ:
                    <span class="text-primary">{{ leave_item.remaining.days }}ມື້
                      {{ leave_item.remaining.hours }}ຊົ່ວໂມງ
                      {{ leave_item.remaining.minute }}ນາທີ</span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    ການນຳໃຊ້:
                    <span class="text-success" v-if="leave_item.can_use.status == true">
                      {{ leave_item.can_use.text }}
                    </span>
                    <span class="text-danger" v-else>
                      {{ leave_item.can_use.text }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon @click="deleteEmployeeLeave(leave_item.id)">
                    <v-icon color="red lighten-1">mdi-trash-can</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </td>
        </tr>
      </table>
      <v-row justify="center">
        <v-dialog v-model="dialogDelete" persistent max-width="290" class="lfont">
          <v-card>
            <v-card-title class="text-h5">
              <span class="lfont">ຢືນຢັນການລຶບ ?</span>
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="confirmDeleteEmployeeLeave()" class="lfont">
                ຢືນຢັນ
              </v-btn>
              <v-btn color="red darken-1" text @click="dialogDelete = false" class="lfont">
                ຍົກເລີກ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <ModalResetHoliday :dialog="dialogResetHoliday" @save="(val) => (dialogResetHoliday = val)" :year="year"
          @load="(val) => (isLoading = val)" @success="fetchEmployee" />
      </v-row>
      <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset" @paginate="fetchEmployee">
      </Pagination>
    </div>
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import Pagination from "@/components/Paginate/Pagination";
import Loading from "@/components/Loading";
import ModalResetHoliday from "@/components/EmployeeHoliday/Modals/ModalResetHoliday.vue";
export default {
  components: {
    Pagination,
    Loading,
    ModalResetHoliday,
  },
  data() {
    return {
      dialogResetHoliday: false,
      dialogDelete: false,
      delete_id: "",
      resetLeave: false,
      year: new Date().getFullYear(),
      years: [],
      listBranch: [],
      branch_id: "",
      listDepartment: [],
      department_id: "",
      searchItem: "",
      listEmployee: [],
      pagination: {},
      offset: 10,
      per_page: 10,
      isLoading: false,
    };
  },
  methods: {
    exportExcel() {
      this.isLoading = true;
      let item = {
        filter: this.searchItem,
        department_id: this.department_id,
        branch_id: this.branch_id,
        year: this.year,
      };
      this.$axios
        .post(`company/employee/holiday/excel`, item)
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.$router
              .push({ name: "company.tracking-export-excel" })
              .catch(() => { });
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },

    showEmpLeave(leave) {
      this.resetLeave = false;
      return leave;
    },
    getYears() {
      let year = this.year - 4;
      let years = [];
      for (let i = 5; i > 1; i--) {
        years.push(year + i);
      }
      this.years = years;
    },
    deleteEmployeeLeave(id) {
      this.dialogDelete = true;
      this.delete_id = id;
    },
    confirmDeleteEmployeeLeave() {
      this.dialogDelete = false;
      this.isLoading = true;
      this.$axios
        .delete(`company/delete/employee/holiday/${this.delete_id}`)
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.fetchEmployee();
            this.delete_id = "";
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors(
                "top-right",
                "danger",
                3000,
                obj.message
              );
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
    fetchEmployee() {
      this.resetLeave = true;
      this.isLoading = true;
      this.$axios
        .get(`company/employee/holiday`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.searchItem,
            department_id: this.department_id,
            branch_id: this.branch_id,
            year: this.year,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.listEmployee = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.listEmployee.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    fetchDepartment() {
      this.$axios.get(`company/list/departments/selected`, {}).then((res) => {
        if (res.status === 200) {
          this.listDepartment = res.data.data;
        }
      });
    },
    fetchBranch() {
      this.$axios
        .get(`company/list/company/branches`)
        .then((res) => {
          if (res) {
            this.listBranch = res.data.companyBranches;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    importExcel() {
      this.$router.push({ name: "employee.importData" }).catch(() => { });
    },
  },
  created() {
    this.fetchDepartment();
    this.fetchBranch();
    this.fetchEmployee();
    this.getYears();
  },
};
</script>

<style scoped lang="scss">
.main-container {
  .btn-import {
    background: rgb(111, 153, 231);
    color: white;
  }

  .btn-reset {
    background: rgb(111, 231, 191);
    color: white;
  }

  .section-content {
    padding: 20px;

    .leave-item {
      margin-right: 5px;
      background: rgb(0, 115, 255) !important;
      color: rgb(255, 255, 255) !important;
    }

    .table {
      font-family: $font-family;

      th {
        font-weight: 900;
        border: 1px solid !important;
      }

      td {
        border: 1px solid !important;
      }
    }
  }
}
</style>
