<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{$t("socialSecurity.title")}}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
                      :placeholder="$t('socialSecurity.search')"
                      v-model="searchItem" @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
<!--        <v-btn class="btn-create" @click="onCreate()">-->
<!--         {{$t("socialSecurity.create")}}-->
<!--        </v-btn>-->
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listSocialSecurity.length > 0">
          <thead>
          <tr>
            <th class="text-start">
             {{$t("socialSecurity.table.no")}}
            </th>
            <th class="text-left">
              {{$t("socialSecurity.table.salary")}}
            </th>
            <th class="text-left">
              {{$t("socialSecurity.table.company_charge")}}
            </th>
            <th class="text-left">
              {{$t("socialSecurity.table.employee_charge")}}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,idx) in listSocialSecurity" :key="idx">
            <td>
              {{ idx + 1 }}
            </td>
            <td>
              {{ $helpers.numberFormatter(item.maximum_salary) }}
            </td>

            <td>
              {{ item.company_charge }}
            </td>
            <td>
              {{ item.employee_charge }}
            </td>
            <td>

            </td>
            <td class="text-end">
              <v-menu transition="slide-y-transition"
                      bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  >
                    <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title class="btn-edit" @click="onEdit(item.id)">Edit</v-list-item-title>
                  </v-list-item>
<!--                  <v-list-item>-->
<!--                    <v-list-item-title class="btn-delete" @click="onDeleted(item.id)">Delete</v-list-item-title>-->
<!--                  </v-list-item>-->
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else/>
        <Pagination
            v-if="pagination.total_pages > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchSocialSecurity"
        >
        </Pagination>

        <ModalEdit fullscreen title="ແກ້ໄຂຂໍ້ມູນປະກັນສັງຄົມ">
          <template v-slot="{close}">
            <EditSocialSecurity :socialSecurity_item="socialSecurity_item" @close="close"
                                @success="fetchSocialSecurity"/>
          </template>
        </ModalEdit>

        <ModalDelete>
          <template v-slot="{close}">
            <DeleteSocialSecurity :socialSecurity_id="socialSecurity_id" @close="close" @success="fetchSocialSecurity"/>
          </template>
        </ModalDelete>


        <Loading v-if="isLoading"/>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../../components/Paginate/Pagination";
import Loading from "../../../components/Loading";
import defaultTableNoResult from "../../../components/defaultTableNoResult";
import EditSocialSecurity from "@/components/SocialSecurity/EditSocialSecurity";
import DeleteSocialSecurity from "@/components/SocialSecurity/DeleteSocialSecurity";

export default {
  components: {
    Loading,
    Pagination,
    defaultTableNoResult,
    EditSocialSecurity,
    DeleteSocialSecurity
  },

  data() {
    return {
      offset: 10,
      pagination: {},
      per_page: 10,
      isLoading: true,
      searchItem: "",
      listSocialSecurity: [],
      socialSecurity_item: {},
      socialSecurity_id: "",
    }
  },
  methods: {
    searchFilterItem() {
      this.fetchSocialSecurity();
    },
    onCreate() {
      this.$router.push({name: 'social_security.create'}).catch(() => {
      });
    },

    filterSocialSecurity(socialSecurity_id) {
      return (
          this.listSocialSecurity.filter((item) => {
            return item.id == socialSecurity_id;
          })[0] || {}
      );
    },

    onEdit(socialSecurity_id) {
      this.socialSecurity_item = {
        ...this.filterSocialSecurity(socialSecurity_id)
      };
      this.$store.commit("modalEdit_State", true);
    },

    onDeleted(socialSecurity_id) {
      this.socialSecurity_id = socialSecurity_id;
      this.$store.commit("modalDelete_State", true);
    },

    fetchSocialSecurity() {
      this.$axios.get(`admin/list/social/securities`).then((res) => {
        if (res.status === 200) {
          this.isLoading = false;
          this.listSocialSecurity = res.data.socialSecurities;
          console.log(this.listSocialSecurity)
        }
      }).catch((error) => {
        console.log(error)
        this.isLoading = false;
      })
    }
  },
  created() {
    this.fetchSocialSecurity();
  }
}
</script>

<style scoped lang="scss">

</style>
